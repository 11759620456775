import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Admin' },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/layouts/AuthLayout.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/layouts/AdminLayout.vue'),
    redirect: { name: 'Places' },
    meta: { auth: true },
    children: [
      {
        path: 'places',
        name: 'Places',
        component: () => import('@/views/places/Places.vue'),
        meta: { guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'places/create',
        name: 'NewPlace',
        component: () => import('@/views/places/Place.vue'),
        meta: { mode: 'create', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'places-travel',
        name: 'PlacesTravel',
        component: () => import('@/views/places/PlacesTravel.vue'),
        meta: { guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'places-travel/create',
        name: 'NewPlaceTravel',
        component: () => import('@/views/places/PlaceTravel.vue'),
        meta: { mode: 'create', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: '/places/:id',
        name: 'EditPlace',
        component: () => import('@/views/places/Place.vue'),
        meta: { mode: 'edit', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: '/places-travel/:id',
        name: 'EditPlaceTravel',
        component: () => import('@/views/places/PlaceTravel.vue'),
        meta: { mode: 'edit', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'place-types',
        name: 'PlaceTypes',
        component: () => import('@/views/placeTypes/PlaceTypes.vue'),
        meta: { guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'new-place-type',
        name: 'NewPlaceType',
        component: () => import('@/views/placeTypes/PlaceType.vue'),
        meta: { mode: 'create', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'place-types/:id',
        name: 'EditPlaceType',
        component: () => import('@/views/placeTypes/PlaceType.vue'),
        meta: { mode: 'edit', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'routes',
        name: 'Routes',
        component: () => import('@/views/routes/Routes.vue'),
        meta: { guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'new-route',
        name: 'NewRoute',
        component: () => import('@/views/routes/Route.vue'),
        meta: { mode: 'create', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'routes/:id',
        name: 'EditRoute',
        component: () => import('@/views/routes/Route.vue'),
        meta: { mode: 'edit', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'routes/:id/new-route-point',
        name: 'NewRoutePoint',
        component: () => import('@/views/routePoints/RoutePoint.vue'),
        meta: { mode: 'create', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'routes/:id/route-point/:pointId',
        name: 'EditRoutePoint',
        component: () => import('@/views/routePoints/RoutePoint.vue'),
        meta: { mode: 'edit', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'authors',
        name: 'Authors',
        component: () => import('@/views/authors/Authors.vue'),
        meta: { guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'new-author',
        name: 'NewAuthor',
        component: () => import('@/views/authors/Author.vue'),
        meta: { mode: 'create', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
      {
        path: 'authors/:id',
        name: 'EditAuthor',
        component: () => import('@/views/authors/Author.vue'),
        meta: { mode: 'edit', guard: () => store.dispatch('auth/ActionCheckAuth') },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isGuardedRoute =
    Object.prototype.hasOwnProperty.call(to.meta, 'guard') && typeof to.meta.guard === 'function';

  if (!isGuardedRoute) {
    next();
  } else if (await to.meta.guard()) {
    next();
  } else {
    next({ name: 'Auth' });
  }
});

export default router;
