import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/auth';
import placeTypes from '@/store/placeTypes';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    placeTypes,
  },
  state: {},
  mutations: {},
  actions: {},
});
