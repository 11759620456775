import request from '@/request/requestHandler';

// Auth
function login(data) {
  return request('post', 'auth/login', '', data);
}
function logout() {
  return request('post', 'auth/logout');
}
function me() {
  return request('post', 'auth/me');
}

export const Auth = { login, logout, me };

// PlaceTypes
function getPlaceTypes(data) {
  return request('get', 'place-types', '', data);
}
function getPlaceType(id) {
  return request('get', 'place-types/:id', id);
}
function createPlaceType(data) {
  return request('post', 'place-types', '', data);
}
function updatePlaceType(id, data) {
  return request('put', 'place-types/:id', id, data);
}
function deletePlaceType(id) {
  return request('delete', 'place-types/:id', id);
}
export const PlaceTypes = {
  getPlaceTypes,
  getPlaceType,
  createPlaceType,
  updatePlaceType,
  deletePlaceType,
};

// Place
function getPlaces(data) {
  return request('get', 'places', '', data);
}
function getPlace(id) {
  return request('get', 'places/:id', id);
}
function createPlace(data) {
  return request('post', 'places', '', data);
}
function updatePlace(id, data) {
  return request('put', 'places/:id', id, data);
}
function deletePlace(id) {
  return request('delete', 'places/:id', id);
}

export const Place = {
  getPlaces,
  getPlace,
  createPlace,
  updatePlace,
  deletePlace,
};

// Images
function getPlaceImages(id) {
  return request('get', 'places/:id/images', id);
}
function uploadPlaceImages(placeId, images) {
  return request('postForm', 'places/:id/upload-images', placeId, images);
}
function getImage(id) {
  return request('get', 'images/:id', id);
}
function deleteImage(id) {
  return request('delete', 'images/:id', id);
}
function uploadRouteImage(routeId, images) {
  return request('postForm', 'routes/:id/upload-image', routeId, images);
}
function deleteRouteImage(routeId) {
  return request('post', 'routes/:id/delete-image', routeId);
}

export const Images = {
  getPlaceImages,
  uploadPlaceImages,
  getImage,
  deleteImage,
  uploadRouteImage,
  deleteRouteImage,
};

// Audio
function uploadPlaceAudio(placeId, audio) {
  return request('postForm', 'places/:id/upload-audio-file', placeId, audio);
}
function deletePlaceAudio(placeId) {
  return request('post', 'places/:id/delete-audio-file', placeId);
}
function uploadRoutePointAudio(pointId, audio) {
  return request('postForm', 'route-points/:id/upload-audio-file', pointId, audio);
}
function deleteRoutePointAudio(pointId) {
  return request('post', 'route-points/:id/delete-audio-file', pointId);
}

export const Audio = {
  uploadPlaceAudio,
  deletePlaceAudio,
  uploadRoutePointAudio,
  deleteRoutePointAudio,
};

// Authors
function getAuthors(data) {
  return request('get', 'authors', '', data);
}
function getAuthor(id) {
  return request('get', 'authors/:id', id);
}
function createAuthor(data) {
  return request('post', 'authors', '', data);
}
function updateAuthor(id, data) {
  return request('put', 'authors/:id', id, data);
}
function deleteAuthor(id) {
  return request('delete', 'authors/:id', id);
}

export const Authors = {
  getAuthors,
  getAuthor,
  createAuthor,
  updateAuthor,
  deleteAuthor,
};

// Routes
function getRoutes(data) {
  return request('get', 'routes', '', data);
}
function getRoute(id) {
  return request('get', 'routes/:id', id);
}
function createRoute(data) {
  return request('post', 'routes', '', data);
}
function updateRoute(id, data) {
  return request('put', 'routes/:id', id, data);
}
function deleteRoute(id) {
  return request('delete', 'routes/:id', id);
}

export const Routes = {
  getRoutes,
  getRoute,
  createRoute,
  updateRoute,
  deleteRoute,
};

// RoutePoints
function getRoutePoints(data) {
  return request('get', 'route-points', '', data);
}
function getRoutePoint(id) {
  return request('get', 'route-points/:id', id);
}
function createRoutePoint(data) {
  return request('post', 'route-points', '', data);
}
function updateRoutePoint(id, data) {
  return request('put', 'route-points/:id', id, data);
}
function deleteRoutePoint(id) {
  return request('delete', 'route-points/:id', id);
}

export const RoutePoints = {
  getRoutePoints,
  getRoutePoint,
  createRoutePoint,
  updateRoutePoint,
  deleteRoutePoint,
};
