import { Auth } from '@/request';

const state = {
  isLoggedIn: false,
};

const getters = {};

const mutations = {
  SetIsLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
};

const actions = {
  async ActionLogin({ state, commit }, credentials) {
    const loginResponse = await Auth.login(credentials);
    localStorage.setItem('token', loginResponse.access_token);
    commit('SetIsLoggedIn', true);
  },
  async ActionLogout({ state, commit }) {
    await Auth.logout();
    localStorage.removeItem('token');
    commit('SetIsLoggedIn', false);
  },
  async ActionCheckAuth({ state, commit }) {
    if (state.isLoggedIn) {
      return true;
    }

    try {
      await Auth.me();
      commit('SetIsLoggedIn', true);
      return true;
    } catch {
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
