import axios from 'axios';
import qs from 'qs';

const requestInstance = axios.create({
  // baseURL: process.env.VUE_APP_SERVER_URL,
  baseURL: 'https://api.orel-app.ru/api', // Fast fix, will remove after correct setting server
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

requestInstance.interceptors.request.use((config) => {
  config.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

function generateUrl(url, ids) {
  if (ids) {
    if (typeof ids === 'object') {
      for (const key in ids) {
        url = url.replace(`:${key}`, ids[key]);
      }
    }
    return url.replace(':id', ids);
  }
  return url.replace('/:id', '');
}

export default function request(method, url, ids = null, data) {
  let req = data;
  if (method === 'get') {
    req = {
      params: data,
      paramsSerializer: (requestParams) =>
        qs.stringify(requestParams, { arrayFormat: 'brackets', encode: false }),
    };
  }

  return new Promise((resolve, reject) => {
    requestInstance[method](generateUrl(url, ids), req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
