import { PlaceTypes } from '@/request';

// TODO Fix this nonsense (store generator)
const state = {
  placeTypes: [],
  lastQuery: undefined,
};

const getters = {};

const mutations = {
  SetPlaceTypes(state, payload) {
    state.placeTypes = payload;
    state.lastQuery = Date.now();
  },
};

const actions = {
  async ActionGetPlaceTypes({ state, commit }, forced = false) {
    const wasLoaded =
      !forced && Boolean(state.lastQuery) && Date.now() - state.lastQuery < 5 * 60 * 1000;

    if (!wasLoaded) {
      const { data } = await PlaceTypes.getPlaceTypes({ per_page: 999 });
      commit('SetPlaceTypes', data);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
